import Vue from 'vue'
import Vuetable from 'vuetable-2'
import vSelect from 'vue-select'
import axios from "axios";
// import $ from 'jquery'
Vue.component('v-select', vSelect)
import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import DualListBox from "dual-listbox-vue";


export default {
    components: {
        Vuetable,
        vSelect,
        axios,
        Datepicker,
        DualListBox
    },
    data: function() {
        return {
            base_url: Vue.BASE_URL,
            filtremodaltitle: "",
            listeCompte: [],
            plan_comptable_id: "",
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            DualListSource: [],
            DualListDestination: [],
            date_exercice: {
                date_debut: "",
                date_fin: ""
            },
            afficherToutExercice: "",
            filtretexte : "",
            filtreDate: {
                dateDebut:"",
                dateFin:""
            },

        }
    },
    methods: {
        onChangeList: function ({ source, destination }) {
            this.DualListSource = source;
            this.DualListDestination = destination;
            this.filtretexte = JSON.stringify(destination);
        },
        openFiltreModal(){
            // this.getAllCompte();
            this.filtremodaltitle = "Filtre avancé - Choisir les comptes à afficher";
            this.$bvModal.show("filtremodalChoixCompte");
        },
        closeFiltreModal(){
            this.$bvModal.hide("filtremodalChoixCompte");
            // this.$parent.setFilter();
        },
        getAllCompte() {
            var that = this;
            axios.get(that.base_url+"/plancomptable/getall").then(response => {
                that.DualListSource = response.data;
            });
        },
        getDateExercice() {
            var that = this;
            axios.get(that.base_url+"/exercice/get/1").then(response => {
                console.log(response.data);
                that.date_exercice.date_debut = moment(response.data[0].date_debut).format('DD/MM/YYYY');
                that.date_exercice.date_fin = moment(response.data[0].date_fin).format('DD/MM/YYYY');
            });

        },
        changeExercice() {
            if(this.$refs.afficherToutExercice.checked == true) {
                this.$parent.filtreDate.dateDebut = this.date_exercice.date_debut;
                this.$parent.filtreDate.dateFin = this.date_exercice.date_fin;
                this.filtreDate.dateDebut = this.date_exercice.date_debut;
                this.filtreDate.dateFin = this.date_exercice.date_fin;
            } else {
                this.$parent.filtreDate.dateDebut = moment().format('DD/MM/YYYY');
                this.$parent.filtreDate.dateFin = moment().format('DD/MM/YYYY');
                this.filtreDate.dateDebut = moment().format('DD/MM/YYYY');
                this.filtreDate.dateFin = moment().format('DD/MM/YYYY');
            }
        },
        initListCompte(filtre) {
            //filtre = this.crudform.parent_id;
            axios.get(this.base_url+"/plancomptable/fetchall?sort=num_compte|asc&page=1&fromselect=&per_page=25&filter="+filtre).then(response => {
                this.listeCompte = response.data.data;
                //console.log(this.listeCompte);
            });
        },
        sendFiltreModal() {
            
            this.$parent.moreParams.startDate = this.filtreDate.dateDebut;
            this.$parent.moreParams.endDate = this.filtreDate.dateFin;
            this.$parent.setFilter();
            this.closeFiltreModal();
        }
    },
    computed: {
        
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";

        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;

        this.getDateExercice();
        this.getAllCompte();
        
    },
    mounted: function() {
        this.initListCompte('');
    }
}
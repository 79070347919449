import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import VuetablePaginationDropdown from 'vuetable-2/src/components/VuetablePaginationDropdown'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import vSelect from 'vue-select'
import axios from "axios";
import $ from 'jquery'
//import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)
import moment from "moment";
import Datepicker from 'vuejs-datepicker';

import ModalChoixCompte from '../../components/ChoixCompte/ModalChoixCompte.vue';

export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin,
        VuetablePaginationDropdown,
        VuetablePaginationInfo,
        vSelect,
        axios,
        Datepicker,
        ModalChoixCompte
    },
    data: function() {
        return {
            pageTitle: "Journal",
            base_url: Vue.BASE_URL,
            moreParams: {},
            crudmodaltitle: "",
            listeCompte: [],
            plan_comptable_id: "",
            totalDebit: 0,
            totalCredit: 0,
            filtreDate: {
                dateDebut:"",
                dateFin:""
            },
            crudform: {
                parent_id: "",
                num_compte: "",
                intitule: "",
                auxiliaires: "",
                observations: "",
            },
            fields: [
                {
                    name: 'date',
                    title: 'Date',
                    width: "100px",
                    sortable: true,
                    titleClass: "text-center",
                    formatter: (value) => { 
                      return (value === null)
                        ? ''
                        : moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
                    }
                },
                {
                    name: 'code_journaux',
                    title: 'Code J.',
                    sortable: false,
                    width: "90px",
                    dataClass: "text-center text-bold",
                    titleClass: "text-center"
                },
                {
                    name: 'num_ecriture',
                    title: 'N° Ecriture',
                    sortable: false,
                    width: "90px",
                    dataClass: "text-center text-bold",
                    titleClass: "text-center"
                },
                {
                    name: 'compte',
                    title: 'Compte',
                    sortable: false,
                    width: "120px",
                    dataClass: "text-center text-bold",
                    titleClass: "text-center"
                },
                {
                    name: 'description',
                    title: 'Déscription',
                    sortable: false
                },
                {
                    name: 'compte_aux',
                    title: 'Tiers/Auxiliaire',
                    sortable: false,
                    dataClass: "text-center text-bold",
                    titleClass: "text-center"
                },
                {
                    name: 'libelle',
                    title: 'Libellé',
                    sortable: false
                },
                {
                    name: 'montant_debit',
                    title: 'Débit',
                    sortable: false,
                    width: "150px",
                    dataClass: "text-right text-bold",
                    titleClass: "text-right"
                },
                {
                    name: 'montant_credit',
                    title: 'Crédit',
                    sortable: false,
                    width: "150px",
                    dataClass: "text-right text-bold",
                    titleClass: "text-right"
                },
                {
                    name: 'actions',
                    title: '-',
                    width: "50px",
                    dataClass: "text-center"
                }
            ],
            sortOrder: [
                { field: 'date', direction: 'asc' }
            ],
            css: {
                table: {
                    tableClass: 'table table-striped table-bordered table-hovered',
                    loadingClass: 'loading',
                    ascendingIcon: 'fas fa-chevron-up',
                    descendingIcon: 'fas fa-chevron-down',
                    handleIcon: 'fas fa-spinner',
                },
                pagination: {
                    infoClass: 'pull-left ',
                    wrapperClass: 'vuetable-pagination text-center',
                    activeClass: 'btn-secondary',
                    disabledClass: 'disabled',
                    pageClass: 'btn btn-border',
                    linkClass: 'btn btn-border',
                    icons: {
                        first: '',
                        prev: '',
                        next: '',
                        last: '',
                    },
                }
            },
            motCle: "",
            tabledata: [],
            json_fields: {
                'Date': 'date',
                'Déscription': 'description',
                'Débit': 'debit',
                'Crédit': 'credit'
            },
            json_data: [],
            json_meta: [
                [{
                    'key': 'charset',
                    'value': 'utf-8'
                }]
            ],
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            DualListSource: [],
            DualListDestination: [],
            date_exercice: {
                date_debut: "",
                date_fin: ""
            },
            afficherToutExercice: "",
            filtretexte : "",
            montant_total_debit: 0,
            montant_total_credit: 0,

        }
    },
    methods: {
        
        choisirCompte() {
            // console.log(this.$refs.modalModalChoixCompte);
            // this.$refs.modalModalChoixCompte.getAllCompte();
            this.$refs.modalModalChoixCompte.filtreDate.dateDebut = this.filtreDate.dateDebut;
            this.$refs.modalModalChoixCompte.filtreDate.dateFin = this.filtreDate.dateFin;
            this.$refs.modalModalChoixCompte.openFiltreModal();
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        onLoading() {
            console.log('loading... show your spinner here');
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
            this.calculSommeTotal();
        },
        setFilter() {
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
            
            //console.log("-----------");
            //console.log(this.$refs.vuetable.tableData);

        },
        calculSommeTotal() {

            var totalDebit = 0;
            var totalCredit = 0;
            Object.entries(this.$refs.vuetable.tableData).forEach(([key, val]) => {
                //console.log(val.debit)
                console.log(key);
                if(val.debit != "")
                    totalDebit += Number(val.montant);
                if(val.credit != "")
                    totalCredit += Number(val.montant);
            });

            this.totalDebit = totalDebit;
            this.totalCredit = totalCredit;

            /*this.$refs.vuetable.tableData.push({
                'date':'',
                'description':'TOTAL',
                'debit':totalDebit,
                'credit':totalCredit
            });*/

            /*console.log("-----------");
            console.log(totalDebit);
            console.log(totalCredit);*/

        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.tabledata = this.$refs.vuetable.$data.tableData;
            this.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        initListCompte(filtre) {
            //filtre = this.crudform.parent_id;
            axios.get(this.base_url+"/plancomptable/fetchall?sort=num_compte|asc&page=1&fromselect=&per_page=25&filter="+filtre).then(response => {
                this.listeCompte = response.data.data;
                //console.log(this.listeCompte);
            });
        },
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
        
        
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";

        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;

        // this.choisirCompte();
        
    },
    mounted: function() {
        // var that = this;
        this.initListCompte('');

        
        /*axios.get("https://vuetable.ratiw.net/api/users").then(response => {
            this.fields = response.data.data;
        });*/

        // On change zone de texte
        /*that.moreParams.filter = 'Mot a rechercher';
            Vue.nextTick(() => that.$refs.vuetable.refresh());*/
    }
}